import { $themeConfig } from '@themeConfig'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    app: {
      AppName: $themeConfig.app.appName,
      Logo: $themeConfig.app.appLogoImage,
      Modules: $themeConfig.app.modules ?? [],
      BasePath: $themeConfig.app.BasePath ?? "",
    },
    layout: {
      isRTL: $themeConfig.layout.isRTL,
      skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin,
      routerTransition: $themeConfig.layout.routerTransition,
      type: $themeConfig.layout.type,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: $themeConfig.layout.navbar.type,
        backgroundColor: $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: $themeConfig.layout.footer.type,
      },
    },
    configs: {
      portal: $themeConfig.configs,
    },
    user: {
      userData: null,
    },
  },
  getters: {
    getConfigValue: state => code => {
      try {
        return state.app.configs.filter(x => x.Code == code)[0].Value
      } catch (error) {
        return ''
      }
    },
    getUserData: state => {
      try {
        return state.user.userData
      } catch (error) {
        return null
      }
    },
  },
  mutations: {
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin

      // Update value in localStorage
      localStorage.setItem('vuexy-skin', skin)

      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)
    },
    UPDATE_APPNAME(state, val) {
      state.app.AppName = val
    },
    UPDATE_APPLOGO(state, val) {
      state.app.Logo = val
    },
    UPDATE_APPBASEPATH(state, val) {
      state.app.BasePath = val
    },
    UPDATE_APPMODULES(state, val) {
      state.app.Modules = val
    },
    UPDATE_APPCONFIG(state, obj) {
      state.app.configs = obj
    },
    UPDATE_USER(state, obj) {
      state.user.userData = obj
    },

  },
  actions: {
    fetchCompanyConfigs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/base/company/info')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPortalConfigs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/base/getportalconfigs')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
