// import cards from './cards'
// import components from './components'
// import extensions from './extensions'
// import ui from './ui'
import pageLayouts from './page-layouts'

// const data = [...cards, ...components, ...extensions, ...ui, ...pageLayouts]
const data = [...pageLayouts]

export default data
