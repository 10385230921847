import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  bundles: [
    {
      id: 1,
      name: "Pacchetto base",
      product: {
        id: 4901,
        name: "Generali: Mass Market",
        company: {
          id: 1,
          name: "Generali",
        },
      },
    },
    {
      id: 2,
      name: "Pacchetto completo",
      product: {
        id: 4901,
        name: "Generali: Mass Market",
        company: {
          id: 1,
          name: "Generali",
        },
      },
    },
    {
      id: 3,
      name: "Pacchetto completo + collisione",
      product: {
        id: 4901,
        name: "Generali: Mass Market",
        company: {
          id: 1,
          name: "Generali",
        },
      },
    },
    {
      id: 4,
      name: "Pacchetto completo + collisione e kasko",
      product: {
        id: 4901,
        name: "Generali: Mass Market",
        company: {
          id: 1,
          name: "Generali",
        },
      },
    },
    {
      id: 5,
      name: "Pacchetto completo + kasko",
      product: {
        id: 4901,
        name: "Generali: Mass Market",
        company: {
          id: 1,
          name: "Generali",
        },
      },
    },
  ],
}


/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/wsdlr/bundle/bundles').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.bundles.filter(
    bundles =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (bundles.name.toLowerCase().includes(queryLowered) ||
      bundles.product.name.toLowerCase().includes(queryLowered)),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      bundles: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})


// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------


// ------------------------------------------------
// GET: Return Single Company
// ------------------------------------------------
mock.onGet(/\/apps\/product\/products\/\d+/).reply(config => {
  // Get event id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.products.findIndex(e => e.id === productId)
  const product = data.products[productIndex]
  const responseData = {
    product,
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905',
    },
  }

  if (product) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/product/countries').reply(() => {
  const { countries } = data
  return [200, countries]
})
