import Vue from 'vue'

// axios
import axios from 'axios'

const development = process.env.NODE_ENV !== 'production'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // eslint-disable-next-line no-undef
  // baseURL: `http://localhost:8185`,
  baseURL: '', // development ? 'http://localhost:80' : ``,
  // baseURL: ``,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
})

Vue.prototype.$http = axiosIns

export default axiosIns
