import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  dataSuggest: [
    {
      docs: [
        { name: 'SLOperation' },
        { name: 'ErrorWarning' },
        { name: 'DocEntry' },
        { name: 'DocNum' },
        { name: 'DocType' },
        { name: 'HandWritten' },
        { name: 'Printed' },
        { name: 'DocDate' },
        { name: 'DocDueDate' },
        { name: 'CardCode' },
        { name: 'CardName' },
        { name: 'Address' },
        { name: 'NumAtCard' },
        { name: 'DocTotal' },
        { name: 'AttachmentEntry' },
        { name: 'DocCurrency' },
        { name: 'DocRate' },
        { name: 'Reference1' },
        { name: 'Reference2' },
        { name: 'Comments' },
        { name: 'JournalMemo' },
        { name: 'PaymentGroupCode' },
        { name: 'DocTime' },
        { name: 'SalesPersonCode' },
        { name: 'TransportationCode' },
        { name: 'Confirmed' },
        { name: 'ImportFileNum' },
        { name: 'SummeryType' },
        { name: 'ContactPersonCode' },
        { name: 'ShowSCN' },
        { name: 'Series' },
        { name: 'TaxDate' },
        { name: 'PartialSupply' },
        { name: 'DocObjectCode' },
        { name: 'ShipToCode' },
        { name: 'Indicator' },
        { name: 'FederalTaxID' },
        { name: 'DiscountPercent' },
        { name: 'PaymentReference' },
        { name: 'CreationDate' },
        { name: 'UpdateDate' },
        { name: 'FinancialPeriod' },
        { name: 'TransNum' },
        { name: 'VatSum' },
        { name: 'VatSumSys' },
        { name: 'VatSumFc' },
        { name: 'NetProcedure' },
        { name: 'DocTotalFc' },
        { name: 'DocTotalSys' },
        { name: 'Form1099' },
        { name: 'Box1099' },
        { name: 'RevisionPo' },
        { name: 'RequriedDate' },
        { name: 'CancelDate' },
        { name: 'BlockDunning' },
        { name: 'Submitted' },
        { name: 'Segment' },
        { name: 'PickStatus' },
        { name: 'Pick' },
        { name: 'PaymentMethod' },
        { name: 'PaymentBlock' },
        { name: 'PaymentBlockEntry' },
        { name: 'CentralBankIndicator' },
        { name: 'MaximumCashDiscount' },
        { name: 'Reserve' },
        { name: 'Project' },
        { name: 'ExemptionValidityDateFrom' },
        { name: 'ExemptionValidityDateTo' },
        { name: 'WareHouseUpdateType' },
        { name: 'Rounding' },
        { name: 'ExternalCorrectedDocNum' },
        { name: 'InternalCorrectedDocNum' },
        { name: 'NextCorrectingDocument' },
        { name: 'DeferredTax' },
        { name: 'TaxExemptionLetterNum' },
        { name: 'WTApplied' },
        { name: 'WTAppliedFC' },
        { name: 'BillOfExchangeReserved' },
        { name: 'AgentCode' },
        { name: 'WTAppliedSC' },
        { name: 'TotalEqualizationTax' },
        { name: 'TotalEqualizationTaxFC' },
        { name: 'TotalEqualizationTaxSC' },
        { name: 'NumberOfInstallments' },
        { name: 'ApplyTaxOnFirstInstallment' },
        { name: 'WTNonSubjectAmount' },
        { name: 'WTNonSubjectAmountSC' },
        { name: 'WTNonSubjectAmountFC' },
        { name: 'WTExemptedAmount' },
        { name: 'WTExemptedAmountSC' },
        { name: 'WTExemptedAmountFC' },
        { name: 'BaseAmount' },
        { name: 'BaseAmountSC' },
        { name: 'BaseAmountFC' },
        { name: 'WTAmount' },
        { name: 'WTAmountSC' },
        { name: 'WTAmountFC' },
        { name: 'VatDate' },
        { name: 'DocumentsOwner' },
        { name: 'FolioPrefixString' },
        { name: 'FolioNumber' },
        { name: 'DocumentSubType' },
        { name: 'BPChannelCode' },
        { name: 'BPChannelContact' },
        { name: 'Address2' },
        { name: 'DocumentStatus' },
        { name: 'PeriodIndicator' },
        { name: 'PayToCode' },
        { name: 'ManualNumber' },
        { name: 'UseShpdGoodsAct' },
        { name: 'IsPayToBank' },
        { name: 'PayToBankCountry' },
        { name: 'PayToBankCode' },
        { name: 'PayToBankAccountNo' },
        { name: 'PayToBankBranch' },
        { name: 'BPL_IDAssignedToInvoice' },
        { name: 'DownPayment' },
        { name: 'ReserveInvoice' },
        { name: 'LanguageCode' },
        { name: 'TrackingNumber' },
        { name: 'PickRemark' },
        { name: 'ClosingDate' },
        { name: 'SequenceCode' },
        { name: 'SequenceSerial' },
        { name: 'SeriesString' },
        { name: 'SubSeriesString' },
        { name: 'SequenceModel' },
        { name: 'UseCorrectionVATGroup' },
        { name: 'TotalDiscount' },
        { name: 'DownPaymentAmount' },
        { name: 'DownPaymentPercentage' },
        { name: 'DownPaymentType' },
        { name: 'DownPaymentAmountSC' },
        { name: 'DownPaymentAmountFC' },
        { name: 'VatPercent' },
        { name: 'ServiceGrossProfitPercent' },
        { name: 'OpeningRemarks' },
        { name: 'ClosingRemarks' },
        { name: 'RoundingDiffAmount' },
        { name: 'RoundingDiffAmountFC' },
        { name: 'RoundingDiffAmountSC' },
        { name: 'Cancelled' },
        { name: 'SignatureInputMessage' },
        { name: 'SignatureDigest' },
        { name: 'CertificationNumber' },
        { name: 'PrivateKeyVersion' },
        { name: 'ControlAccount' },
        { name: 'InsuranceOperation347' },
        { name: 'ArchiveNonremovableSalesQuotation' },
        { name: 'GTSChecker' },
        { name: 'GTSPayee' },
        { name: 'ExtraMonth' },
        { name: 'ExtraDays' },
        { name: 'CashDiscountDateOffset' },
        { name: 'StartFrom' },
        { name: 'NTSApproved' },
        { name: 'ETaxWebSite' },
        { name: 'ETaxNumber' },
        { name: 'NTSApprovedNumber' },
        { name: 'EDocGenerationType' },
        { name: 'EDocSeries' },
        { name: 'EDocNum' },
        { name: 'EDocExportFormat' },
        { name: 'EDocStatus' },
        { name: 'EDocErrorCode' },
        { name: 'EDocErrorMessage' },
        { name: 'DownPaymentStatus' },
        { name: 'GroupSeries' },
        { name: 'GroupNumber' },
        { name: 'GroupHandWritten' },
        { name: 'ReopenOriginalDocument' },
        { name: 'ReopenManuallyClosedOrCanceledDocument' },
        { name: 'CreateOnlineQuotation' },
        { name: 'POSEquipmentNumber' },
        { name: 'POSManufacturerSerialNumber' },
        { name: 'POSCashierNumber' },
        { name: 'ApplyCurrentVATRatesForDownPaymentsToDraw' },
        { name: 'ClosingOption' },
        { name: 'SpecifiedClosingDate' },
        { name: 'OpenForLandedCosts' },
        { name: 'AuthorizationStatus' },
        { name: 'TotalDiscountFC' },
        { name: 'TotalDiscountSC' },
        { name: 'RelevantToGTS' },
        { name: 'BPLName' },
        { name: 'VATRegNum' },
        { name: 'AnnualInvoiceDeclarationReference' },
        { name: 'Supplier' },
        { name: 'Releaser' },
        { name: 'Receiver' },
        { name: 'BlanketAgreementNumber' },
        { name: 'IsAlteration' },
        { name: 'CancelStatus' },
        { name: 'AssetValueDate' },
        { name: 'DocumentDelivery' },
        { name: 'AuthorizationCode' },
        { name: 'StartDeliveryDate' },
        { name: 'StartDeliveryTime' },
        { name: 'EndDeliveryDate' },
        { name: 'EndDeliveryTime' },
        { name: 'VehiclePlate' },
        { name: 'ATDocumentType' },
        { name: 'ElecCommStatus' },
        { name: 'ElecCommMessage' },
        { name: 'ReuseDocumentNum' },
        { name: 'ReuseNotaFiscalNum' },
        { name: 'PrintSEPADirect' },
        { name: 'FiscalDocNum' },
        { name: 'POSDailySummaryNo' },
        { name: 'POSReceiptNo' },
        { name: 'PointOfIssueCode' },
        { name: 'Letter' },
        { name: 'FolioNumberFrom' },
        { name: 'FolioNumberTo' },
        { name: 'InterimType' },
        { name: 'RelatedType' },
        { name: 'RelatedEntry' },
        { name: 'SAPPassport' },
        { name: 'DocumentTaxID' },
        { name: 'DateOfReportingControlStatementVAT' },
        { name: 'ReportingSectionControlStatementVAT' },
        { name: 'ExcludeFromTaxReportControlStatementVAT' },
        { name: 'POS_CashRegister' },
        { name: 'UpdateTime' },
        { name: 'PriceMode' },
        { name: 'Revision' },
        { name: 'OriginalRefNo' },
        { name: 'OriginalRefDate' },
        { name: 'GSTTransactionType' },
        { name: 'OriginalCreditOrDebitNo' },
        { name: 'OriginalCreditOrDebitDate' },
        { name: 'ECommerceOperator' },
        { name: 'ECommerceGSTIN' },
        { name: 'ShipFrom' },
        { name: 'CommissionTrade' },
        { name: 'CommissionTradeReturn' },
        { name: 'UseBillToAddrToDetermineTax' },
        { name: 'IssuingReason' },
        { name: 'Cig' },
        { name: 'Cup' },
        { name: 'EDocType' },
        { name: 'PaidToDate' },
        { name: 'PaidToDateFC' },
        { name: 'PaidToDateSys' },
        { name: 'U_MM1_DOC_RIF' },
        { name: 'U_MM1_T_ORD' },
        { name: 'U_MM1_PESO_N_KG' },
        { name: 'U_MM1_PESO_L_KG' },
        { name: 'U_MM1_N_COLLI' },
        { name: 'U_MM1_ASPETTO_BENI' },
        { name: 'U_MM1_DT_INI_TR' },
        { name: 'U_MM1_ORA_INI_TR' },
        { name: 'U_MM1_GEST_PROVV' },
        { name: 'U_MM1_DT_PROD' },
        { name: 'U_MM1_C_GRP' },
        { name: 'U_MM1_IND_REVISIONE' },
        { name: 'U_MM1_C_LIV_AZ' },
        { name: 'U_MM1_FABBXTRASF' },
        { name: 'U_MM1_TCONS' },
        { name: 'U_MM1_STATOGG_TES' },
        { name: 'U_MM1_STATO_AVANZ' },
        { name: 'U_MM1_TIPOLOGIA_DOC' },
        { name: 'U_MM1_C_RESA' },
        { name: 'U_MM1_RESA_EST' },
        { name: 'U_MM1_AUT_TRASP' },
        { name: 'U_MM1_C_CAUS_TRASP' },
        { name: 'U_MM1_VETTORE_1' },
        { name: 'U_Tipo_Doc' },
        { name: 'U_MM1_CAUSALE' },
        { name: 'U_MM1_MARK_COLLI' },
        { name: 'U_MM1_NOTE' },
        { name: 'U_MM1_EXP_EDI' },
        { name: 'U_SM_PARTITA' },
        { name: 'U_MM1_ORA_BOLLA' },
        { name: 'U_MM1_DATA_EMISSIONE' },
        { name: 'U_MM1_ORA_EMISSIONE' },
        { name: 'U_CLIENTE' },
        { name: 'U_MM1_MAG_FINALE' },
        { name: 'U_MM1_DOCNUM_EDI' },
        { name: 'U_CIP' },
        { name: 'U_UnqImpId' },
        { name: 'U_ORD_RIB' },
        { name: 'U_CLI_RIB' },
        { name: 'U_DT_CONS_PR' },
        { name: 'U_ORA_CONS_PR' },
        { name: 'U_VETT2' },
        { name: 'U_DT_STAMP' },
        { name: 'U_GroupNum' },
        { name: 'U_B1SYS_INV_TYPE' },
        { name: 'U_CLAV_SUPP' },
        { name: 'U_NumAtCard' },
        { name: 'U_BPDocDate' },
        { name: 'Document_ApprovalRequests' },
        { name: 'DocumentLines' },
        { name: 'DocumentLines.LineNum' },
        { name: 'DocumentLines.ItemCode' },
        { name: 'DocumentLines.ItemDescription' },
        { name: 'DocumentLines.Quantity' },
        { name: 'DocumentLines.ShipDate' },
        { name: 'DocumentLines.Price' },
        { name: 'DocumentLines.PriceAfterVAT' },
        { name: 'DocumentLines.Currency' },
        { name: 'DocumentLines.Rate' },
        { name: 'DocumentLines.DiscountPercent' },
        { name: 'DocumentLines.VendorNum' },
        { name: 'DocumentLines.SerialNum' },
        { name: 'DocumentLines.WarehouseCode' },
        { name: 'DocumentLines.SalesPersonCode' },
        { name: 'DocumentLines.CommisionPercent' },
        { name: 'DocumentLines.TreeType' },
        { name: 'DocumentLines.AccountCode' },
        { name: 'DocumentLines.UseBaseUnits' },
        { name: 'DocumentLines.SupplierCatNum' },
        { name: 'DocumentLines.CostingCode' },
        { name: 'DocumentLines.ProjectCode' },
        { name: 'DocumentLines.BarCode' },
        { name: 'DocumentLines.VatGroup' },
        { name: 'DocumentLines.Height1' },
        { name: 'DocumentLines.Hight1Unit' },
        { name: 'DocumentLines.Height2' },
        { name: 'DocumentLines.Height2Unit' },
        { name: 'DocumentLines.Lengh1' },
        { name: 'DocumentLines.Lengh1Unit' },
        { name: 'DocumentLines.Lengh2' },
        { name: 'DocumentLines.Lengh2Unit' },
        { name: 'DocumentLines.Weight1' },
        { name: 'DocumentLines.Weight1Unit' },
        { name: 'DocumentLines.Weight2' },
        { name: 'DocumentLines.Weight2Unit' },
        { name: 'DocumentLines.Factor1' },
        { name: 'DocumentLines.Factor2' },
        { name: 'DocumentLines.Factor3' },
        { name: 'DocumentLines.Factor4' },
        { name: 'DocumentLines.BaseType' },
        { name: 'DocumentLines.BaseEntry' },
        { name: 'DocumentLines.BaseLine' },
        { name: 'DocumentLines.Volume' },
        { name: 'DocumentLines.VolumeUnit' },
        { name: 'DocumentLines.Width1' },
        { name: 'DocumentLines.Width1Unit' },
        { name: 'DocumentLines.Width2' },
        { name: 'DocumentLines.Width2Unit' },
        { name: 'DocumentLines.Address' },
        { name: 'DocumentLines.TaxCode' },
        { name: 'DocumentLines.TaxType' },
        { name: 'DocumentLines.TaxLiable' },
        { name: 'DocumentLines.PickStatus' },
        { name: 'DocumentLines.PickQuantity' },
        { name: 'DocumentLines.PickListIdNumber' },
        { name: 'DocumentLines.OriginalItem' },
        { name: 'DocumentLines.BackOrder' },
        { name: 'DocumentLines.FreeText' },
        { name: 'DocumentLines.ShippingMethod' },
        { name: 'DocumentLines.POTargetNum' },
        { name: 'DocumentLines.POTargetEntry' },
        { name: 'DocumentLines.POTargetRowNum' },
        { name: 'DocumentLines.CorrectionInvoiceItem' },
        { name: 'DocumentLines.CorrInvAmountToStock' },
        { name: 'DocumentLines.CorrInvAmountToDiffAcct' },
        { name: 'DocumentLines.AppliedTax' },
        { name: 'DocumentLines.AppliedTaxFC' },
        { name: 'DocumentLines.AppliedTaxSC' },
        { name: 'DocumentLines.WTLiable' },
        { name: 'DocumentLines.DeferredTax' },
        { name: 'DocumentLines.EqualizationTaxPercent' },
        { name: 'DocumentLines.TotalEqualizationTax' },
        { name: 'DocumentLines.TotalEqualizationTaxFC' },
        { name: 'DocumentLines.TotalEqualizationTaxSC' },
        { name: 'DocumentLines.NetTaxAmount' },
        { name: 'DocumentLines.NetTaxAmountFC' },
        { name: 'DocumentLines.NetTaxAmountSC' },
        { name: 'DocumentLines.MeasureUnit' },
        { name: 'DocumentLines.UnitsOfMeasurment' },
        { name: 'DocumentLines.LineTotal' },
        { name: 'DocumentLines.TaxPercentagePerRow' },
        { name: 'DocumentLines.TaxTotal' },
        { name: 'DocumentLines.ConsumerSalesForecast' },
        { name: 'DocumentLines.ExciseAmount' },
        { name: 'DocumentLines.TaxPerUnit' },
        { name: 'DocumentLines.TotalInclTax' },
        { name: 'DocumentLines.CountryOrg' },
        { name: 'DocumentLines.SWW' },
        { name: 'DocumentLines.TransactionType' },
        { name: 'DocumentLines.DistributeExpense' },
        { name: 'DocumentLines.RowTotalFC' },
        { name: 'DocumentLines.RowTotalSC' },
        { name: 'DocumentLines.LastBuyInmPrice' },
        { name: 'DocumentLines.LastBuyDistributeSumFc' },
        { name: 'DocumentLines.LastBuyDistributeSumSc' },
        { name: 'DocumentLines.LastBuyDistributeSum' },
        { name: 'DocumentLines.StockDistributesumForeign' },
        { name: 'DocumentLines.StockDistributesumSystem' },
        { name: 'DocumentLines.StockDistributesum' },
        { name: 'DocumentLines.StockInmPrice' },
        { name: 'DocumentLines.PickStatusEx' },
        { name: 'DocumentLines.TaxBeforeDPM' },
        { name: 'DocumentLines.TaxBeforeDPMFC' },
        { name: 'DocumentLines.TaxBeforeDPMSC' },
        { name: 'DocumentLines.CFOPCode' },
        { name: 'DocumentLines.CSTCode' },
        { name: 'DocumentLines.Usage' },
        { name: 'DocumentLines.TaxOnly' },
        { name: 'DocumentLines.VisualOrder' },
        { name: 'DocumentLines.BaseOpenQuantity' },
        { name: 'DocumentLines.UnitPrice' },
        { name: 'DocumentLines.LineStatus' },
        { name: 'DocumentLines.PackageQuantity' },
        { name: 'DocumentLines.Text' },
        { name: 'DocumentLines.LineType' },
        { name: 'DocumentLines.COGSCostingCode' },
        { name: 'DocumentLines.COGSAccountCode' },
        { name: 'DocumentLines.ChangeAssemlyBoMWarehouse' },
        { name: 'DocumentLines.GrossBuyPrice' },
        { name: 'DocumentLines.GrossBase' },
        { name: 'DocumentLines.GrossProfitTotalBasePrice' },
        { name: 'DocumentLines.CostingCode2' },
        { name: 'DocumentLines.CostingCode3' },
        { name: 'DocumentLines.CostingCode4' },
        { name: 'DocumentLines.CostingCode5' },
        { name: 'DocumentLines.ItemDetails' },
        { name: 'DocumentLines.LocationCode' },
        { name: 'DocumentLines.ActualDeliveryDate' },
        { name: 'DocumentLines.RemainingOpenQuantity' },
        { name: 'DocumentLines.OpenAmount' },
        { name: 'DocumentLines.OpenAmountFC' },
        { name: 'DocumentLines.OpenAmountSC' },
        { name: 'DocumentLines.ExLineNo' },
        { name: 'DocumentLines.RequiredDate' },
        { name: 'DocumentLines.RequiredQuantity' },
        { name: 'DocumentLines.COGSCostingCode2' },
        { name: 'DocumentLines.COGSCostingCode3' },
        { name: 'DocumentLines.COGSCostingCode4' },
        { name: 'DocumentLines.COGSCostingCode5' },
        { name: 'DocumentLines.CSTforIPI' },
        { name: 'DocumentLines.CSTforPIS' },
        { name: 'DocumentLines.CSTforCOFINS' },
        { name: 'DocumentLines.CreditOriginCode' },
        { name: 'DocumentLines.WithoutInventoryMovement' },
        { name: 'DocumentLines.AgreementNo' },
        { name: 'DocumentLines.AgreementRowNumber' },
        { name: 'DocumentLines.ActualBaseEntry' },
        { name: 'DocumentLines.ActualBaseLine' },
        { name: 'DocumentLines.DocEntry' },
        { name: 'DocumentLines.Surpluses' },
        { name: 'DocumentLines.DefectAndBreakup' },
        { name: 'DocumentLines.Shortages' },
        { name: 'DocumentLines.ConsiderQuantity' },
        { name: 'DocumentLines.PartialRetirement' },
        { name: 'DocumentLines.RetirementQuantity' },
        { name: 'DocumentLines.RetirementAPC' },
        { name: 'DocumentLines.ThirdParty' },
        { name: 'DocumentLines.ExpenseType' },
        { name: 'DocumentLines.ReceiptNumber' },
        { name: 'DocumentLines.ExpenseOperationType' },
        { name: 'DocumentLines.FederalTaxID' },
        { name: 'DocumentLines.StgSeqNum' },
        { name: 'DocumentLines.StgEntry' },
        { name: 'DocumentLines.StgDesc' },
        { name: 'DocumentLines.UoMEntry' },
        { name: 'DocumentLines.UoMCode' },
        { name: 'DocumentLines.InventoryQuantity' },
        { name: 'DocumentLines.RemainingOpenInventoryQuantity' },
        { name: 'DocumentLines.ParentLineNum' },
        { name: 'DocumentLines.Incoterms' },
        { name: 'DocumentLines.TransportMode' },
        { name: 'DocumentLines.ItemType' },
        { name: 'DocumentLines.ChangeInventoryQuantityIndependently' },
        { name: 'DocumentLines.FreeOfChargeBP' },
        { name: 'DocumentLines.SACEntry' },
        { name: 'DocumentLines.HSNEntry' },
        { name: 'DocumentLines.GrossPrice' },
        { name: 'DocumentLines.GrossTotal' },
        { name: 'DocumentLines.GrossTotalFC' },
        { name: 'DocumentLines.GrossTotalSC' },
        { name: 'DocumentLines.NCMCode' },
        { name: 'DocumentLines.ShipToCode' },
        { name: 'DocumentLines.ShipToDescription' },
        { name: 'DocumentLines.U_MM1_DOC_ORIG' },
        { name: 'DocumentLines.U_MM1_POS_DOC' },
        { name: 'DocumentLines.U_MM1_DT_PREC' },
        { name: 'DocumentLines.U_MM1_PAKL_C_SRL' },
        { name: 'DocumentLines.U_MM1_QTA_X_IMBALLO' },
        { name: 'DocumentLines.U_MM1_QTA_ORIG' },
        { name: 'DocumentLines.U_MM1_PAKL_C_SRL_OR' },
        { name: 'DocumentLines.U_MM1_C_LOTTO_CL' },
        { name: 'DocumentLines.U_MM1_NOTE_RIGA' },
        { name: 'DocumentLines.U_MM1_IMBALLO_EST' },
        { name: 'DocumentLines.U_MM1_T_IMBALLO' },
        { name: 'DocumentLines.U_MM1_QTA_OFFSET' },
        { name: 'DocumentLines.U_MM1_RIF_DISEGNO' },
        { name: 'DocumentLines.U_MM1_PRZ_OFFSET' },
        { name: 'DocumentLines.U_MM1_C_PART_RIF' },
        { name: 'DocumentLines.U_MM1_FLG_CONFORME' },
        { name: 'DocumentLines.U_MM1_DT_PROD' },
        { name: 'DocumentLines.U_MM1_WHSCODE_ORIG' },
        { name: 'DocumentLines.U_MM1_ESCLUDE_QC' },
        { name: 'DocumentLines.U_MM1_BASEREF' },
        { name: 'DocumentLines.U_MM1_BASETYPE' },
        { name: 'DocumentLines.U_MM1_BASEENTRY' },
        { name: 'DocumentLines.U_MM1_C_LIV_AZ' },
        { name: 'DocumentLines.U_MM1_Um_Stock' },
        { name: 'DocumentLines.U_MM1_STATOGG' },
        { name: 'DocumentLines.U_MM1_DT_CONS_RT' },
        { name: 'DocumentLines.U_MM1_Qta_Um_Stock' },
        { name: 'DocumentLines.U_MM1_COMM_RIF' },
        { name: 'DocumentLines.U_MM1_C_ITEM' },
        { name: 'DocumentLines.U_MM1_C_PART_ALT' },
        { name: 'DocumentLines.U_MM1_PREZZO' },
        { name: 'DocumentLines.U_MM1_FLG_CONF' },
        { name: 'DocumentLines.U_MM1_N_COLLI' },
        { name: 'DocumentLines.U_MM1_NOTE_COLLI' },
        { name: 'DocumentLines.U_MOLO' },
        { name: 'DocumentLines.U_RigaImballo' },
        { name: 'DocumentLines.U_MM1_CONS_STOCK' },
        { name: 'DocumentLines.U_MM1_DOCENTRY_RT' },
        { name: 'DocumentLines.U_MM1_LINENUM_RT' },
        { name: 'DocumentLines.U_MM1_OBJTYPE_RT' },
        { name: 'DocumentLines.U_MM1_MOLO' },
        { name: 'DocumentLines.U_MM1_PARTNER_CODE' },
        { name: 'DocumentLines.U_MM1_STABILIMENTO' },
        { name: 'DocumentLines.U_SCCS' },
        { name: 'DocumentLines.U_MM1_ORDER_EDI' },
        { name: 'DocumentLines.U_SM_DESC_COGE' },
        { name: 'DocumentLines.U_SM_PRJ_AS' },
        { name: 'DocumentLines.U_MM1_DOCENTRY_PDN1' },
        { name: 'DocumentLines.U_MM1_LINENUM_PDN1' },
        { name: 'DocumentLines.U_MM1_OBJTYPE_PDN1' },
        { name: 'DocumentLines.U_PackCode' },
        { name: 'DocumentLines.U_EmpID' },
        { name: 'DocumentLines.U_Causale' },
        { name: 'DocumentLines.U_PartnerCode' },
        { name: 'DocumentLines.U_DelPlatfrm' },
        { name: 'DocumentLines.U_CodDest' },
        { name: 'DocumentLines.U_B1SYS_Discount' },
        { name: 'DocumentLines.U_B1SYS_Discount_FC' },
        { name: 'DocumentLines.U_B1SYS_Discount_SC' },
        { name: 'DocumentLines.U_B1SYS_DiscountVat' },
        { name: 'DocumentLines.U_B1SYS_DiscountVtFC' },
        { name: 'DocumentLines.U_B1SYS_DiscountVtSC' },
        { name: 'DocumentLines.U_NumAtCard' },
        { name: 'DocumentLines.U_QtyXImb' },
        { name: 'DocumentLines.U_ReqDate' },
        { name: 'DocumentLines.U_SM_CLFC' },
        { name: 'DocumentLines.U_BinCodeF' },
        { name: 'DocumentLines.U_BinCodeT' },
        { name: 'DocumentLines.LineTaxJurisdictions' },
        { name: 'DocumentLines.ExportProcesses' },
        { name: 'DocumentLines.DocumentLineAdditionalExpenses' },
        { name: 'DocumentLines.WithholdingTaxLines' },
        { name: 'DocumentLines.SerialNumbers' },
        { name: 'DocumentLines.BatchNumbers' },
        { name: 'DocumentLines.CCDNumbers' },
        { name: 'DocumentLines.DocumentLinesBinAllocations' },
        { name: 'EWayBillDetails' },
        { name: 'ElectronicProtocols' },
        { name: 'DocumentAdditionalExpenses' },
        { name: 'DocumentAdditionalExpenses.ExpenseCode' },
        { name: 'DocumentAdditionalExpenses.LineTotal' },
        { name: 'DocumentAdditionalExpenses.LineTotalFC' },
        { name: 'DocumentAdditionalExpenses.LineTotalSys' },
        { name: 'DocumentAdditionalExpenses.PaidToDate' },
        { name: 'DocumentAdditionalExpenses.PaidToDateFC' },
        { name: 'DocumentAdditionalExpenses.PaidToDateSys' },
        { name: 'DocumentAdditionalExpenses.Remarks' },
        { name: 'DocumentAdditionalExpenses.DistributionMethod' },
        { name: 'DocumentAdditionalExpenses.TaxLiable' },
        { name: 'DocumentAdditionalExpenses.VatGroup' },
        { name: 'DocumentAdditionalExpenses.TaxPercent' },
        { name: 'DocumentAdditionalExpenses.TaxSum' },
        { name: 'DocumentAdditionalExpenses.TaxSumFC' },
        { name: 'DocumentAdditionalExpenses.TaxSumSys' },
        { name: 'DocumentAdditionalExpenses.DeductibleTaxSum' },
        { name: 'DocumentAdditionalExpenses.DeductibleTaxSumFC' },
        { name: 'DocumentAdditionalExpenses.DeductibleTaxSumSys' },
        { name: 'DocumentAdditionalExpenses.AquisitionTax' },
        { name: 'DocumentAdditionalExpenses.TaxCode' },
        { name: 'DocumentAdditionalExpenses.TaxType' },
        { name: 'DocumentAdditionalExpenses.TaxPaid' },
        { name: 'DocumentAdditionalExpenses.TaxPaidFC' },
        { name: 'DocumentAdditionalExpenses.TaxPaidSys' },
        { name: 'DocumentAdditionalExpenses.EqualizationTaxPercent' },
        { name: 'DocumentAdditionalExpenses.EqualizationTaxSum' },
        { name: 'DocumentAdditionalExpenses.EqualizationTaxFC' },
        { name: 'DocumentAdditionalExpenses.EqualizationTaxSys' },
        { name: 'DocumentAdditionalExpenses.TaxTotalSum' },
        { name: 'DocumentAdditionalExpenses.TaxTotalSumFC' },
        { name: 'DocumentAdditionalExpenses.TaxTotalSumSys' },
        { name: 'DocumentAdditionalExpenses.BaseDocEntry' },
        { name: 'DocumentAdditionalExpenses.BaseDocLine' },
        { name: 'DocumentAdditionalExpenses.BaseDocType' },
        { name: 'DocumentAdditionalExpenses.BaseDocumentReference' },
        { name: 'DocumentAdditionalExpenses.LineNum' },
        { name: 'DocumentAdditionalExpenses.LastPurchasePrice' },
        { name: 'DocumentAdditionalExpenses.Status' },
        { name: 'DocumentAdditionalExpenses.Stock' },
        { name: 'DocumentAdditionalExpenses.TargetAbsEntry' },
        { name: 'DocumentAdditionalExpenses.TargetType' },
        { name: 'DocumentAdditionalExpenses.WTLiable' },
        { name: 'DocumentAdditionalExpenses.DistributionRule' },
        { name: 'DocumentAdditionalExpenses.Project' },
        { name: 'DocumentAdditionalExpenses.DistributionRule2' },
        { name: 'DocumentAdditionalExpenses.DistributionRule3' },
        { name: 'DocumentAdditionalExpenses.DistributionRule4' },
        { name: 'DocumentAdditionalExpenses.DistributionRule5' },
        { name: 'DocumentAdditionalExpenses.LineGross' },
        { name: 'DocumentAdditionalExpenses.LineGrossSys' },
        { name: 'DocumentAdditionalExpenses.LineGrossFC' },
        { name: 'DocumentAdditionalExpenses.U_ScFin' },
        { name: 'DocumentAdditionalExpenses.DocExpenseTaxJurisdictions' },
        { name: 'WithholdingTaxDataWTXCollection' },
        { name: 'WithholdingTaxDataCollection' },
        { name: 'DocumentPackages' },
        { name: 'DocumentSpecialLines' },
        { name: 'DocumentInstallments' },
        { name: 'DocumentInstallments.DueDate' },
        { name: 'DocumentInstallments.Percentage' },
        { name: 'DocumentInstallments.Total' },
        { name: 'DocumentInstallments.LastDunningDate' },
        { name: 'DocumentInstallments.DunningLevel' },
        { name: 'DocumentInstallments.TotalFC' },
        { name: 'DocumentInstallments.InstallmentId' },
        { name: 'DocumentInstallments.PaymentOrdered' },
        { name: 'DownPaymentsToDraw' },
        { name: 'TaxExtension' },
        { name: 'TaxExtension.TaxId0' },
        { name: 'TaxExtension.TaxId1' },
        { name: 'TaxExtension.TaxId2' },
        { name: 'TaxExtension.TaxId3' },
        { name: 'TaxExtension.TaxId4' },
        { name: 'TaxExtension.TaxId5' },
        { name: 'TaxExtension.TaxId6' },
        { name: 'TaxExtension.TaxId7' },
        { name: 'TaxExtension.TaxId8' },
        { name: 'TaxExtension.TaxId9' },
        { name: 'TaxExtension.State' },
        { name: 'TaxExtension.County' },
        { name: 'TaxExtension.Incoterms' },
        { name: 'TaxExtension.Vehicle' },
        { name: 'TaxExtension.VehicleState' },
        { name: 'TaxExtension.NFRef' },
        { name: 'TaxExtension.Carrier' },
        { name: 'TaxExtension.PackQuantity' },
        { name: 'TaxExtension.PackDescription' },
        { name: 'TaxExtension.Brand' },
        { name: 'TaxExtension.ShipUnitNo' },
        { name: 'TaxExtension.NetWeight' },
        { name: 'TaxExtension.GrossWeight' },
        { name: 'TaxExtension.StreetS' },
        { name: 'TaxExtension.BlockS' },
        { name: 'TaxExtension.BuildingS' },
        { name: 'TaxExtension.CityS' },
        { name: 'TaxExtension.ZipCodeS' },
        { name: 'TaxExtension.CountyS' },
        { name: 'TaxExtension.StateS' },
        { name: 'TaxExtension.CountryS' },
        { name: 'TaxExtension.StreetB' },
        { name: 'TaxExtension.BlockB' },
        { name: 'TaxExtension.BuildingB' },
        { name: 'TaxExtension.CityB' },
        { name: 'TaxExtension.ZipCodeB' },
        { name: 'TaxExtension.CountyB' },
        { name: 'TaxExtension.StateB' },
        { name: 'TaxExtension.CountryB' },
        { name: 'TaxExtension.ImportOrExport' },
        { name: 'TaxExtension.MainUsage' },
        { name: 'TaxExtension.GlobalLocationNumberS' },
        { name: 'TaxExtension.GlobalLocationNumberB' },
        { name: 'TaxExtension.TaxId12' },
        { name: 'TaxExtension.TaxId13' },
        { name: 'TaxExtension.BillOfEntryNo' },
        { name: 'TaxExtension.BillOfEntryDate' },
        { name: 'TaxExtension.OriginalBillOfEntryNo' },
        { name: 'TaxExtension.OriginalBillOfEntryDate' },
        { name: 'TaxExtension.ImportOrExportType' },
        { name: 'TaxExtension.PortCode' },
        { name: 'TaxExtension.DocEntry' },
        { name: 'TaxExtension.BoEValue' },
        { name: 'TaxExtension.ClaimRefund' },
        { name: 'TaxExtension.DifferentialOfTaxRate' },
        { name: 'TaxExtension.IsIGSTAccount' },
        { name: 'AddressExtension' },
        { name: 'AddressExtension.ShipToStreet' },
        { name: 'AddressExtension.ShipToStreetNo' },
        { name: 'AddressExtension.ShipToBlock' },
        { name: 'AddressExtension.ShipToBuilding' },
        { name: 'AddressExtension.ShipToCity' },
        { name: 'AddressExtension.ShipToZipCode' },
        { name: 'AddressExtension.ShipToCounty' },
        { name: 'AddressExtension.ShipToState' },
        { name: 'AddressExtension.ShipToCountry' },
        { name: 'AddressExtension.ShipToAddressType' },
        { name: 'AddressExtension.BillToStreet' },
        { name: 'AddressExtension.BillToStreetNo' },
        { name: 'AddressExtension.BillToBlock' },
        { name: 'AddressExtension.BillToBuilding' },
        { name: 'AddressExtension.BillToCity' },
        { name: 'AddressExtension.BillToZipCode' },
        { name: 'AddressExtension.BillToCounty' },
        { name: 'AddressExtension.BillToState' },
        { name: 'AddressExtension.BillToCountry' },
        { name: 'AddressExtension.BillToAddressType' },
        { name: 'AddressExtension.ShipToGlobalLocationNumber' },
        { name: 'AddressExtension.BillToGlobalLocationNumber' },
        { name: 'AddressExtension.ShipToAddress2' },
        { name: 'AddressExtension.ShipToAddress3' },
        { name: 'AddressExtension.BillToAddress2' },
        { name: 'AddressExtension.BillToAddress3' },
        { name: 'AddressExtension.PlaceOfSupply' },
        { name: 'AddressExtension.PurchasePlaceOfSupply' },
        { name: 'AddressExtension.DocEntry' },
        { name: 'DocumentReferences' },
        { name: 'DocumentReferences.DocEntry' },
        { name: 'DocumentReferences.LineNumber' },
        { name: 'DocumentReferences.RefDocEntr' },
        { name: 'DocumentReferences.RefDocNum' },
        { name: 'DocumentReferences.ExtDocNum' },
        { name: 'DocumentReferences.RefObjType' },
        { name: 'DocumentReferences.AccessKey' },
        { name: 'DocumentReferences.IssueDate' },
        { name: 'DocumentReferences.IssuerCNPJ' },
        { name: 'DocumentReferences.IssuerCode' },
        { name: 'DocumentReferences.Model' },
        { name: 'DocumentReferences.Series' },
        { name: 'DocumentReferences.Number' },
        { name: 'DocumentReferences.RefAccKey' },
        { name: 'DocumentReferences.RefAmount' },
        { name: 'DocumentReferences.SubSeries' },
        { name: 'DocumentReferences.Remark' },
        { name: 'DocumentReferences.LinkRefTyp' },
        { name: 'DocumentReferences.__BaseDocDate' },
        { name: 'DocumentReferences.__BaseRef' },
        { name: 'DocumentReferences.__BaseContractNum' },
        { name: 'DocumentReferences.__ItemCodeMov' },
      ],
      delins: [
        { name: 'SLOperation' },
        { name: 'ErrorWarning' },
        { name: 'DocNum' },
        { name: 'Period' },
        { name: 'Instance' },
        { name: 'Series' },
        { name: 'Handwrtten' },
        { name: 'Status' },
        { name: 'RequestStatus' },
        { name: 'Creator' },
        { name: 'Remark' },
        { name: 'DocEntry' },
        { name: 'Canceled' },
        { name: 'Object' },
        { name: 'LogInst' },
        { name: 'UserSign' },
        { name: 'Transfered' },
        { name: 'CreateDate' },
        { name: 'CreateTime' },
        { name: 'UpdateDate' },
        { name: 'UpdateTime' },
        { name: 'DataSource' },
        { name: 'U_CtrNum' },
        { name: 'U_CardCode' },
        { name: 'U_CardName' },
        { name: 'U_ExtCardCode' },
        { name: 'U_ItemCode' },
        { name: 'U_ItemName' },
        { name: 'U_SubCatNum' },
        { name: 'U_Price' },
        { name: 'U_DefUDC' },
        { name: 'U_DefQtyUDC' },
        { name: 'U_WhsPick' },
        { name: 'U_WhsDest' },
        { name: 'U_DelPlatfrm' },
        { name: 'U_Molo' },
        { name: 'U_FlowType' },
        { name: 'U_GenODC' },
        { name: 'U_GenRDT' },
        { name: 'U_LastDocRec' },
        { name: 'U_LastProgRec' },
        { name: 'U_FrmCalcDat' },
        { name: 'U_LstCalcDat' },
        { name: 'U_LstCumSchd' },
        { name: 'U_LstCumRec' },
        { name: 'U_LstQtyBal' },
        { name: 'U_ItmStatus' },
        { name: 'U_SenderCode' },
        { name: 'U_ActionCode' },
        { name: 'U_Identific' },
        { name: 'U_CQualifier' },
        { name: 'U_RRtngAddr' },
        { name: 'U_PartnerCode' },
        { name: 'U_SQualifier' },
        { name: 'U_RtngAddr' },
        { name: 'U_OrdDate' },
        { name: 'U_CodDest' },
        { name: 'SM_DELINS_RCollection' },
        { name: 'SM_DELINS_RCollection.DocEntry' },
        { name: 'SM_DELINS_RCollection.LineId' },
        { name: 'SM_DELINS_RCollection.VisOrder' },
        { name: 'SM_DELINS_RCollection.Object' },
        { name: 'SM_DELINS_RCollection.LogInst' },
        { name: 'SM_DELINS_RCollection.U_OrdDate' },
        { name: 'SM_DELINS_RCollection.U_DelDate' },
        { name: 'SM_DELINS_RCollection.U_DelDateTo' },
        { name: 'SM_DELINS_RCollection.U_ModDate' },
        { name: 'SM_DELINS_RCollection.U_InitQty' },
        { name: 'SM_DELINS_RCollection.U_Qty' },
        { name: 'SM_DELINS_RCollection.U_Type' },
        { name: 'SM_DELINS_RCollection.U_WhsPick' },
        { name: 'SM_DELINS_RCollection.U_CodDest' },
        { name: 'SM_DELINS_RCollection.U_Program' },
        { name: 'SM_DELINS_RCollection.U_Note' },
        { name: 'SM_DELINS_RCollection.U_RelType' },
        { name: 'SM_DELINS_RCollection.U_ValFrom' },
        { name: 'SM_DELINS_RCollection.U_ValTo' },
        { name: 'SM_DELINS_ACollection' },
      ],
      sloperation: [{ name: 'Orders' }],
    },
  ],
}
/* eslint-disable global-require */

mock.onGet('/autosuggest/edi').reply(() => [200, data.dataSuggest])
