import store from '@/store'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/extensions, import/no-cycle
import useAuth from '@/auth/useAuth'


const AuthState = {
  Authorized: "Authorized",
  notAuthorized: "notAuthorized",
  notAuthenticated: "notAuthenticated",
  AuthenticatedRegistrationNotVerified: "AuthenticatedRegistrationNotVerified",
}

export const getUserData = () => {
  if (process.env.VUE_APP_AUTH == "CL") return store.getters['appConfig/getUserData']
  return JSON.parse(localStorage.getItem('userData'))
}

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {
  console.log("==================================isUserLoggedIn????????????????????????????????????????????????")
  const userData = getUserData()
  if (userData == null && process.env.VUE_APP_AUTH == "CL") {
    await useAuth.fetchAuthUser()
  }
  // console.log(localStorage.getItem(useAuth.authConfig.AuthStateKeyName))
  if (process.env.VUE_APP_AUTH == "OP") return getUserData() && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  return getUserData() && localStorage.getItem(useAuth.authConfig.AuthStateKeyName) == AuthState.Authorized
}


/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userData => {
  console.log('=================USERDATA START=========================')
  console.log(userData)
  console.log('=================USERDATA END=========================')
  const userRole = userData.role ? userData.role : userData
  console.log(userRole)
  if (userData.id == '1') return { name: 'dashboard-home' }
  if (userData) {
    if (userData.basepath) {
      console.log('=================USERDATA CUSTOMPATH=========================')
      console.log(userData.basepath)
      window.location.href = userData.basepath
      // return { path: userData.basepath }
    }
    const basePath = localStorage.getItem('APPBASEPATH')
    if (basePath) {
      if (basePath != 'null') {
        console.log('=================USERDATA BASEPATH=========================')
        console.log(basePath)
        window.location.href = basePath
      // return { path: basePath }
      }
    }
  }
  // return { name: 'dashboard-home' }
  // if (userRole === 'admin') return { name: 'dashboard-home' }
  // if (userRole === 'client') return { name: 'dashboard-home' }
  if (userRole === 'agent') window.location.href = "/crm/#/"
  if (userRole) return { name: 'dashboard-home' }
  if (process.env.VUE_APP_AUTH !== 'CL' && !userRole) {
    return { name: 'auth-login' }
  }
  return { name: 'dashboard-home' }


  // return { name: 'auth-login' }
}

// export const getHomeRouteForLoggedInUser = userRole => {
//   console.log($themeConfig)
//   // if (userRole === 'superuser') return { name: 'dashboard-home' }
//   const userData = getUserData()
//   if (userData) {
//     if (userData.basepath === null || userData.basepath === '') {
//       if ($themeConfig.app.BasePath !== null && userData.basepath !== '') {
//         return { path: $themeConfig.app.BasePath }
//       }
//     } else {
//       return { path: userData.basepath }
//     }
//   }
//   // if (userRole === 'admin') return { name: 'dashboard-home' }
//   // if (userRole === 'client') return { name: 'dashboard-home' }
//   // if (userRole === 'superuser') return { name: 'dashboard-home' }
//   // if (userRole === 'agent') return { name: 'dashboard-home' }
//   return { name: 'auth-login' }
// }
