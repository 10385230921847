import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  roles: [
    {
      name: 'admin',
      abilities: [

        /* Users */
        {
          subject: "Users",
          read: true,
          write: true,
          create: true,
          delete: true,
        },
      ],
    },
  ],
}

/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/roles/abilities').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    roleFilter = '',
  } = config.params
  /* eslint-enable */
  const filteredData = data.roles.filter(
    role =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      role.name === (roleFilter || role.name),
  )

  return [
    200,
    {
      roles: filteredData,
    },
  ]
})
// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/roles\/abilities\/\d+/).reply(config => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex(e => e.id === userId)
  const user = data.users[userIndex]

  if (user) return [200, user]
  return [404]
})
