import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// Demo Routes
// eslint-disable-next-line import/no-cycle
import useAuth from '@/auth/useAuth'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
// Production Routes
import japps from './jroutes/apps'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  hash: true,
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-home' } },
    ...japps,
    ...pages,
    ...uiElements,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = await isUserLoggedIn() ?? false
  // return next({ name: 'japps-users-list' })

  console.log(`is logged in ${isLoggedIn}`)
  console.log(to)
  console.log(!canNavigate(to))
  if (!canNavigate(to)) {
    console.log("AAA")
    // Redirect to login if not logged in
    if (process.env.VUE_APP_AUTH !== 'CL') {
      if (!isLoggedIn) { return next({ name: 'auth-login' }) }
      console.log("BBB")
      return next({ name: 'misc-not-authorized' })
    }
    // if (!isLoggedIn &&  ) {
    //  return next({ name: 'auth-login' })
    //   console.log(process.env.VUE_APP_AUTH)
    //   // if (process.env.VUE_APP_AUTH == "OP") { return next({ name: 'auth-login' }) }
    //   // if (process.env.VUE_APP_AUTH == "CL") { return window.location.replace('/oauth/login') }
    //   // console.log("==========fetchAuthUser=============")
    //   await useAuth.fetchAuthUser()
    //   // console.log("==========fetchAuthUser=============")
    //   // console.log("==========LOGIN=============")
    //   // return window.location.replace('/oauth/login')
    // } else {
    if (isLoggedIn) {
      console.log("BBB")
      // If logged in => not authorized
      return next({ name: 'misc-not-authorized' })
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    console.log("CCC")
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData))
  }

  if (to.redirectedFrom == "/" && isLoggedIn) {
    console.log("RRR")
    const userData = getUserData()
    // const newto = getHomeRouteForLoggedInUser(userData)
    // console.log(`NEW TO => ${JSON.stringify(newto)}`)
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  console.log("DDD")
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(async (to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (!canNavigate(to)) {
    const isLoggedIn = await isUserLoggedIn() ?? false
    if (!isLoggedIn) {
      await useAuth.fetchAuthUser().then(() => { if (appLoading) { appLoading.style.display = 'none' } })
    }
  } else if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
