import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  countries: [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' },
  ],
  companies: [
    {
      id: 4987,
      address: "7777 Mendez Plains",
      company: "Hall-Robbins PLC",
      companyEmail: "don85@johnson.com",
      contact: "(616) 865-4180",
      name: "Jordan Stevenson",
      country: "USA",
    },
    {
      id: 4988,
      address: "04033 Wesley Wall Apt. 961",
      company: "Mccann LLC and Sons",
      companyEmail: "brenda49@taylor.info",
      contact: "(226) 204-8287",
      name: "Stephanie Burns",
      country: "Haiti",
    },
    {
      id: 4989,
      address: "5345 Robert Squares",
      company: "Leonard-Garcia and Sons",
      companyEmail: "smithtiffany@powers.com",
      contact: "(955) 676-1076",
      name: "Tony Herrera",
      country: "Denmark",
    },
    {
      id: 4990,
      address: "19022 Clark Parks Suite 149",
      company: "Smith, Miller and Henry LLC",
      companyEmail: "mejiageorge@lee-perez.com",
      contact: "(832) 323-6914",
      name: "Kevin Patton",
      country: "Cambodia",
    },
    {
      id: 4991,
      address: "8534 Saunders Hill Apt. 583",
      company: "Garcia-Cameron and Sons",
      companyEmail: "brandon07@pierce.com",
      contact: "(970) 982-3353",
      name: "Mrs. Julie Donovan MD",
      country: "Martinique",
    },
    {
      id: 4992,
      address: "661 Perez Run Apt. 778",
      company: "Burnett-Young PLC",
      companyEmail: "guerrerobrandy@beasley-harper.com",
      contact: "(511) 938-9617",
      name: "Amanda Phillips",
      country: "Botswana",
    },
    {
      id: 4993,
      address: "074 Long Union",
      company: "Wilson-Lee LLC",
      companyEmail: "williamshenry@moon-smith.com",
      contact: "(504) 859-2893",
      name: "Christina Collier",
      country: "Montserrat",
    },
    {
      id: 4994,
      address: "5225 Ford Cape Apt. 840",
      company: "Schwartz, Henry and Rhodes Group",
      companyEmail: "margaretharvey@russell-murray.com",
      contact: "(758) 403-7718",
      name: "David Flores",
      country: "Oman",
    },
    {
      id: 4995,
      address: "23717 James Club Suite 277",
      company: "Henderson-Holder PLC",
      companyEmail: "dianarodriguez@villegas.com",
      contact: "(292) 873-8254",
      name: "Valerie Perez",
      country: "Cambodia",
    },
    {
      id: 4996,
      address: "4528 Myers Gateway",
      company: "Page-Wise PLC",
      companyEmail: "bwilson@norris-brock.com",
      contact: "(956) 803-2008",
      name: "Susan Dickerson",
      country: "Guam",
    },
    {
      id: 4997,
      address: "4234 Mills Club Suite 107",
      company: "Turner PLC Inc",
      companyEmail: "markcampbell@bell.info",
      contact: "(716) 962-8635",
      name: "Kelly Smith",
      country: "United States Virgin Islands",
    },
    {
      id: 4998,
      address: "476 Keith Meadow",
      company: "Levine-Dorsey PLC",
      companyEmail: "mary61@rosario.com",
      contact: "(523) 449-0782",
      name: "Jamie Jones",
      country: "Syrian Arab Republic",
    },
    {
      id: 4999,
      address: "56381 Ashley Village Apt. 332",
      company: "Hall, Thompson and Ramirez LLC",
      companyEmail: "sean22@cook.com",
      contact: "(583) 470-8356",
      name: "Ruben Garcia",
      country: "Ukraine",
    },
    {
      id: 5000,
      address: "6946 Gregory Plaza Apt. 310",
      company: "Lambert-Thomas Group",
      companyEmail: "mccoymatthew@lopez-jenkins.net",
      contact: "(366) 906-6467",
      name: "Ryan Meyer",
      country: "Vanuatu",
    },
    {
      id: 5001,
      address: "64351 Andrew Lights",
      company: "Gregory-Haynes PLC",
      companyEmail: "novakshannon@mccarty-murillo.com",
      contact: "(320) 616-3915",
      name: "Valerie Valdez",
      country: "Romania",
    },
    {
      id: 5002,
      address: "5702 Sarah Heights",
      company: "Wright-Schmidt LLC",
      companyEmail: "smithrachel@davis-rose.net",
      contact: "(435) 899-1963",
      name: "Melissa Wheeler",
      country: "Costa Rica",
    },
    {
      id: 5003,
      address: "668 Robert Flats",
      company: "Russell-Abbott Ltd",
      companyEmail: "scott96@mejia.net",
      contact: "(254) 399-4728",
      name: "Alan Jimenez",
      country: "Congo",
    },
    {
      id: 5004,
      address: "55642 Chang Extensions Suite 373",
      company: "Williams LLC Inc",
      companyEmail: "cramirez@ross-bass.biz",
      contact: "(648) 500-4338",
      name: "Jennifer Morris",
      country: "Saint Pierre and Miquelon",
    },
    {
      id: 5005,
      address: "56694 Eric Orchard",
      company: "Hudson, Bell and Phillips PLC",
      companyEmail: "arielberg@wolfe-smith.com",
      contact: "(896) 544-3796",
      name: "Timothy Stevenson",
      country: "Uruguay",
    },
    {
      id: 5006,
      address: "3727 Emma Island Suite 879",
      company: "Berry, Gonzalez and Heath Inc",
      companyEmail: "yrobinson@nichols.com",
      contact: "(236) 784-5142",
      name: "Erik Hayden",
      country: "Israel",
    },
    {
      id: 5007,
      address: "953 Miller Common Suite 580",
      company: "Martinez, Fuller and Chavez and Sons",
      companyEmail: "tatejennifer@allen.net",
      contact: "(436) 717-2419",
      name: "Katherine Kennedy",
      country: "Cook Islands",
    },
    {
      id: 5008,
      address: "808 Sullivan Street Apt. 135",
      company: "Wilson and Sons LLC",
      companyEmail: "gdurham@lee.com",
      contact: "(489) 946-3041",
      name: "Monica Fuller",
      country: "Nepal",
    },
    {
      id: 5009,
      address: "25135 Christopher Creek",
      company: "Hawkins, Johnston and Mcguire PLC",
      companyEmail: "jenny96@lawrence-thompson.com",
      contact: "(274) 246-3725",
      name: "Stacey Carter",
      country: "Kiribati",
    },
    {
      id: 5010,
      address: "81285 Rebecca Estates Suite 046",
      company: "Huynh-Mills and Sons",
      companyEmail: "jgutierrez@jackson.com",
      contact: "(258) 211-5970",
      name: "Chad Davis",
      country: "Swaziland",
    },
    {
      id: 5011,
      address: "3102 Briggs Dale Suite 118",
      company: "Jones-Cooley and Sons",
      companyEmail: "hunter14@jones.com",
      contact: "(593) 965-4100",
      name: "Chris Reyes",
      country: "Congo",
    },
    {
      id: 5012,
      address: "811 Jill Skyway",
      company: "Jones PLC Ltd",
      companyEmail: "pricetodd@johnson-jenkins.com",
      contact: "(585) 829-2603",
      name: "Laurie Summers",
      country: "Brazil",
    },
    {
      id: 5013,
      address: "2223 Brandon Inlet Suite 597",
      company: "Jordan, Gomez and Ross Group",
      companyEmail: "perrydavid@chapman-rogers.com",
      contact: "(527) 351-5517",
      name: "Lindsay Wilson",
      country: "Congo",
    },
    {
      id: 5014,
      address: "08724 Barry Causeway",
      company: "Gonzalez, Moody and Glover LLC",
      companyEmail: "leahgriffin@carpenter.com",
      contact: "(628) 903-0132",
      name: "Jenna Castro",
      country: "Equatorial Guinea",
    },
    {
      id: 5015,
      address: "073 Holt Ramp Apt. 755",
      company: "Ashley-Pacheco Ltd",
      companyEmail: "esparzadaniel@allen.com",
      contact: "(847) 396-9904",
      name: "Wendy Weber",
      country: "Seychelles",
    },
    {
      id: 5016,
      address: "984 Sherry Trail Apt. 953",
      company: "Berry PLC Group",
      companyEmail: "todd34@owens-morgan.com",
      contact: "(852) 249-4539",
      name: "April Yates",
      country: "Ireland",
    },
    {
      id: 5017,
      address: "093 Jonathan Camp Suite 953",
      company: "Allen Group Ltd",
      companyEmail: "roydavid@bailey.com",
      contact: "(917) 984-2232",
      name: "Daniel Marshall PhD",
      country: "Netherlands",
    },
    {
      id: 5018,
      address: "4735 Kristie Islands Apt. 259",
      company: "Chapman-Schneider LLC",
      companyEmail: "baldwinjoel@washington.com",
      contact: "(670) 409-3703",
      name: "Randy Rich",
      country: "Cocos (Keeling) Islands",
    },
    {
      id: 5019,
      address: "92218 Andrew Radial",
      company: "Mcclure, Hernandez and Simon Ltd",
      companyEmail: "psmith@morris.info",
      contact: "(646) 263-0257",
      name: "Mrs. Jodi Chapman",
      country: "Macao",
    },
    {
      id: 5020,
      address: "2342 Michelle Valley",
      company: "Hamilton PLC and Sons",
      companyEmail: "lori06@morse.com",
      contact: "(751) 213-4288",
      name: "Steven Myers",
      country: "Somalia",
    },
    {
      id: 5021,
      address: "16039 Brittany Terrace Apt. 128",
      company: "Silva-Reeves LLC",
      companyEmail: "zpearson@miller.com",
      contact: "(655) 649-7872",
      name: "Charles Alexander",
      country: "Slovakia (Slovak Republic)",
    },
    {
      id: 5022,
      address: "37856 Olsen Lakes Apt. 852",
      company: "Solis LLC Ltd",
      companyEmail: "strongpenny@young.net",
      contact: "(402) 935-0735",
      name: "Elizabeth Jones",
      country: "Brazil",
    },
    {
      id: 5023,
      address: "11489 Griffin Plaza Apt. 927",
      company: "Munoz-Peters and Sons",
      companyEmail: "carrietorres@acosta.com",
      contact: "(915) 448-6271",
      name: "Heidi Walton",
      country: "Argentina",
    },
    {
      id: 5024,
      address: "276 Michael Gardens Apt. 004",
      company: "Shea, Velez and Garcia LLC",
      companyEmail: "zjohnson@nichols-powers.com",
      contact: "(817) 700-2984",
      name: "Christopher Allen",
      country: "Philippines",
    },
    {
      id: 5025,
      address: "633 Bell Well Apt. 057",
      company: "Adams, Simmons and Brown Group",
      companyEmail: "kayla09@thomas.com",
      contact: "(266) 611-9482",
      name: "Joseph Oliver",
      country: "Martinique",
    },
    {
      id: 5026,
      address: "1068 Lopez Fall",
      company: "Williams-Lawrence and Sons",
      companyEmail: "melvindavis@allen.info",
      contact: "(739) 745-9728",
      name: "Megan Roberts",
      country: "Mexico",
    },
    {
      id: 5027,
      address: "86691 Mackenzie Light Suite 568",
      company: "Deleon Inc LLC",
      companyEmail: "gjordan@fernandez-coleman.com",
      contact: "(682) 804-6506",
      name: "Mary Garcia",
      country: "Costa Rica",
    },
    {
      id: 5028,
      address: "86580 Sarah Bridge",
      company: "Farmer, Johnson and Anderson Group",
      companyEmail: "robertscott@garcia.com",
      contact: "(775) 366-0411",
      name: "Crystal Mays",
      country: "Cameroon",
    },
    {
      id: 5029,
      address: "49709 Edwin Ports Apt. 353",
      company: "Sherman-Johnson PLC",
      companyEmail: "desiree61@kelly.com",
      contact: "(510) 536-6029",
      name: "Nicholas Tanner",
      country: "Macedonia",
    },
    {
      id: 5030,
      address: "3856 Mathis Squares Apt. 584",
      company: "Byrd LLC PLC",
      companyEmail: "jeffrey25@martinez-hodge.com",
      contact: "(253) 230-4657",
      name: "Mr. Justin Richardson",
      country: "Congo",
    },
    {
      id: 5031,
      address: "141 Adrian Ridge Suite 550",
      company: "Stone-Zimmerman Group",
      companyEmail: "john77@anderson.net",
      contact: "(612) 546-3485",
      name: "Jennifer Summers",
      country: "Falkland Islands (Malvinas)",
    },
    {
      id: 5032,
      address: "01871 Kristy Square",
      company: "Yang, Hansen and Hart PLC",
      companyEmail: "ywagner@jones.com",
      contact: "(203) 601-8603",
      name: "Richard Payne",
      country: "Germany",
    },
    {
      id: 5033,
      address: "075 Smith Views",
      company: "Jenkins-Rosales Inc",
      companyEmail: "calvin07@joseph-edwards.org",
      contact: "(895) 401-4255",
      name: "Lori Wells",
      country: "Colombia",
    },
    {
      id: 5034,
      address: "2577 Pearson Overpass Apt. 314",
      company: "Mason-Reed PLC",
      companyEmail: "eric47@george-castillo.com",
      contact: "(602) 336-9806",
      name: "Tammy Sanchez",
      country: "Paraguay",
    },
    {
      id: 5035,
      address: "1770 Sandra Mountains Suite 636",
      company: "Foster-Pham PLC",
      companyEmail: "jamesjoel@chapman.net",
      contact: "(936) 550-1638",
      name: "Dana Carey",
      country: "Western Sahara",
    },
    {
      id: 5036,
      address: "78083 Laura Pines",
      company: "Richardson and Sons LLC",
      companyEmail: "pwillis@cross.org",
      contact: "(687) 660-2473",
      name: "Andrew Burns",
      country: "Bhutan",
    },
  ],
  agencies: [
    {
      id: 4987,
      address: "7777 Mendez Plains",
      company: "Hall-Robbins PLC",
      companyEmail: "don85@johnson.com",
      contact: "(616) 865-4180",
      name: "Jordan Stevenson",
      country: "USA",
    },
    {
      id: 4988,
      address: "04033 Wesley Wall Apt. 961",
      company: "Mccann LLC and Sons",
      companyEmail: "brenda49@taylor.info",
      contact: "(226) 204-8287",
      name: "Stephanie Burns",
      country: "Haiti",
    },
    {
      id: 4989,
      address: "5345 Robert Squares",
      company: "Leonard-Garcia and Sons",
      companyEmail: "smithtiffany@powers.com",
      contact: "(955) 676-1076",
      name: "Tony Herrera",
      country: "Denmark",
    },
    {
      id: 4990,
      address: "19022 Clark Parks Suite 149",
      company: "Smith, Miller and Henry LLC",
      companyEmail: "mejiageorge@lee-perez.com",
      contact: "(832) 323-6914",
      name: "Kevin Patton",
      country: "Cambodia",
    },
    {
      id: 4991,
      address: "8534 Saunders Hill Apt. 583",
      company: "Garcia-Cameron and Sons",
      companyEmail: "brandon07@pierce.com",
      contact: "(970) 982-3353",
      name: "Mrs. Julie Donovan MD",
      country: "Martinique",
    },
    {
      id: 4992,
      address: "661 Perez Run Apt. 778",
      company: "Burnett-Young PLC",
      companyEmail: "guerrerobrandy@beasley-harper.com",
      contact: "(511) 938-9617",
      name: "Amanda Phillips",
      country: "Botswana",
    },
    {
      id: 4993,
      address: "074 Long Union",
      company: "Wilson-Lee LLC",
      companyEmail: "williamshenry@moon-smith.com",
      contact: "(504) 859-2893",
      name: "Christina Collier",
      country: "Montserrat",
    },
    {
      id: 4994,
      address: "5225 Ford Cape Apt. 840",
      company: "Schwartz, Henry and Rhodes Group",
      companyEmail: "margaretharvey@russell-murray.com",
      contact: "(758) 403-7718",
      name: "David Flores",
      country: "Oman",
    },
    {
      id: 4995,
      address: "23717 James Club Suite 277",
      company: "Henderson-Holder PLC",
      companyEmail: "dianarodriguez@villegas.com",
      contact: "(292) 873-8254",
      name: "Valerie Perez",
      country: "Cambodia",
    },
    {
      id: 4996,
      address: "4528 Myers Gateway",
      company: "Page-Wise PLC",
      companyEmail: "bwilson@norris-brock.com",
      contact: "(956) 803-2008",
      name: "Susan Dickerson",
      country: "Guam",
    },
    {
      id: 4997,
      address: "4234 Mills Club Suite 107",
      company: "Turner PLC Inc",
      companyEmail: "markcampbell@bell.info",
      contact: "(716) 962-8635",
      name: "Kelly Smith",
      country: "United States Virgin Islands",
    },
    {
      id: 4998,
      address: "476 Keith Meadow",
      company: "Levine-Dorsey PLC",
      companyEmail: "mary61@rosario.com",
      contact: "(523) 449-0782",
      name: "Jamie Jones",
      country: "Syrian Arab Republic",
    },
    {
      id: 4999,
      address: "56381 Ashley Village Apt. 332",
      company: "Hall, Thompson and Ramirez LLC",
      companyEmail: "sean22@cook.com",
      contact: "(583) 470-8356",
      name: "Ruben Garcia",
      country: "Ukraine",
    },
    {
      id: 5000,
      address: "6946 Gregory Plaza Apt. 310",
      company: "Lambert-Thomas Group",
      companyEmail: "mccoymatthew@lopez-jenkins.net",
      contact: "(366) 906-6467",
      name: "Ryan Meyer",
      country: "Vanuatu",
    },
    {
      id: 5001,
      address: "64351 Andrew Lights",
      company: "Gregory-Haynes PLC",
      companyEmail: "novakshannon@mccarty-murillo.com",
      contact: "(320) 616-3915",
      name: "Valerie Valdez",
      country: "Romania",
    },
    {
      id: 5002,
      address: "5702 Sarah Heights",
      company: "Wright-Schmidt LLC",
      companyEmail: "smithrachel@davis-rose.net",
      contact: "(435) 899-1963",
      name: "Melissa Wheeler",
      country: "Costa Rica",
    },
    {
      id: 5003,
      address: "668 Robert Flats",
      company: "Russell-Abbott Ltd",
      companyEmail: "scott96@mejia.net",
      contact: "(254) 399-4728",
      name: "Alan Jimenez",
      country: "Congo",
    },
    {
      id: 5004,
      address: "55642 Chang Extensions Suite 373",
      company: "Williams LLC Inc",
      companyEmail: "cramirez@ross-bass.biz",
      contact: "(648) 500-4338",
      name: "Jennifer Morris",
      country: "Saint Pierre and Miquelon",
    },
    {
      id: 5005,
      address: "56694 Eric Orchard",
      company: "Hudson, Bell and Phillips PLC",
      companyEmail: "arielberg@wolfe-smith.com",
      contact: "(896) 544-3796",
      name: "Timothy Stevenson",
      country: "Uruguay",
    },
    {
      id: 5006,
      address: "3727 Emma Island Suite 879",
      company: "Berry, Gonzalez and Heath Inc",
      companyEmail: "yrobinson@nichols.com",
      contact: "(236) 784-5142",
      name: "Erik Hayden",
      country: "Israel",
    },
    {
      id: 5007,
      address: "953 Miller Common Suite 580",
      company: "Martinez, Fuller and Chavez and Sons",
      companyEmail: "tatejennifer@allen.net",
      contact: "(436) 717-2419",
      name: "Katherine Kennedy",
      country: "Cook Islands",
    },
    {
      id: 5008,
      address: "808 Sullivan Street Apt. 135",
      company: "Wilson and Sons LLC",
      companyEmail: "gdurham@lee.com",
      contact: "(489) 946-3041",
      name: "Monica Fuller",
      country: "Nepal",
    },
    {
      id: 5009,
      address: "25135 Christopher Creek",
      company: "Hawkins, Johnston and Mcguire PLC",
      companyEmail: "jenny96@lawrence-thompson.com",
      contact: "(274) 246-3725",
      name: "Stacey Carter",
      country: "Kiribati",
    },
    {
      id: 5010,
      address: "81285 Rebecca Estates Suite 046",
      company: "Huynh-Mills and Sons",
      companyEmail: "jgutierrez@jackson.com",
      contact: "(258) 211-5970",
      name: "Chad Davis",
      country: "Swaziland",
    },
    {
      id: 5011,
      address: "3102 Briggs Dale Suite 118",
      company: "Jones-Cooley and Sons",
      companyEmail: "hunter14@jones.com",
      contact: "(593) 965-4100",
      name: "Chris Reyes",
      country: "Congo",
    },
    {
      id: 5012,
      address: "811 Jill Skyway",
      company: "Jones PLC Ltd",
      companyEmail: "pricetodd@johnson-jenkins.com",
      contact: "(585) 829-2603",
      name: "Laurie Summers",
      country: "Brazil",
    },
    {
      id: 5013,
      address: "2223 Brandon Inlet Suite 597",
      company: "Jordan, Gomez and Ross Group",
      companyEmail: "perrydavid@chapman-rogers.com",
      contact: "(527) 351-5517",
      name: "Lindsay Wilson",
      country: "Congo",
    },
    {
      id: 5014,
      address: "08724 Barry Causeway",
      company: "Gonzalez, Moody and Glover LLC",
      companyEmail: "leahgriffin@carpenter.com",
      contact: "(628) 903-0132",
      name: "Jenna Castro",
      country: "Equatorial Guinea",
    },
    {
      id: 5015,
      address: "073 Holt Ramp Apt. 755",
      company: "Ashley-Pacheco Ltd",
      companyEmail: "esparzadaniel@allen.com",
      contact: "(847) 396-9904",
      name: "Wendy Weber",
      country: "Seychelles",
    },
    {
      id: 5016,
      address: "984 Sherry Trail Apt. 953",
      company: "Berry PLC Group",
      companyEmail: "todd34@owens-morgan.com",
      contact: "(852) 249-4539",
      name: "April Yates",
      country: "Ireland",
    },
    {
      id: 5017,
      address: "093 Jonathan Camp Suite 953",
      company: "Allen Group Ltd",
      companyEmail: "roydavid@bailey.com",
      contact: "(917) 984-2232",
      name: "Daniel Marshall PhD",
      country: "Netherlands",
    },
    {
      id: 5018,
      address: "4735 Kristie Islands Apt. 259",
      company: "Chapman-Schneider LLC",
      companyEmail: "baldwinjoel@washington.com",
      contact: "(670) 409-3703",
      name: "Randy Rich",
      country: "Cocos (Keeling) Islands",
    },
    {
      id: 5019,
      address: "92218 Andrew Radial",
      company: "Mcclure, Hernandez and Simon Ltd",
      companyEmail: "psmith@morris.info",
      contact: "(646) 263-0257",
      name: "Mrs. Jodi Chapman",
      country: "Macao",
    },
    {
      id: 5020,
      address: "2342 Michelle Valley",
      company: "Hamilton PLC and Sons",
      companyEmail: "lori06@morse.com",
      contact: "(751) 213-4288",
      name: "Steven Myers",
      country: "Somalia",
    },
    {
      id: 5021,
      address: "16039 Brittany Terrace Apt. 128",
      company: "Silva-Reeves LLC",
      companyEmail: "zpearson@miller.com",
      contact: "(655) 649-7872",
      name: "Charles Alexander",
      country: "Slovakia (Slovak Republic)",
    },
    {
      id: 5022,
      address: "37856 Olsen Lakes Apt. 852",
      company: "Solis LLC Ltd",
      companyEmail: "strongpenny@young.net",
      contact: "(402) 935-0735",
      name: "Elizabeth Jones",
      country: "Brazil",
    },
    {
      id: 5023,
      address: "11489 Griffin Plaza Apt. 927",
      company: "Munoz-Peters and Sons",
      companyEmail: "carrietorres@acosta.com",
      contact: "(915) 448-6271",
      name: "Heidi Walton",
      country: "Argentina",
    },
    {
      id: 5024,
      address: "276 Michael Gardens Apt. 004",
      company: "Shea, Velez and Garcia LLC",
      companyEmail: "zjohnson@nichols-powers.com",
      contact: "(817) 700-2984",
      name: "Christopher Allen",
      country: "Philippines",
    },
    {
      id: 5025,
      address: "633 Bell Well Apt. 057",
      company: "Adams, Simmons and Brown Group",
      companyEmail: "kayla09@thomas.com",
      contact: "(266) 611-9482",
      name: "Joseph Oliver",
      country: "Martinique",
    },
    {
      id: 5026,
      address: "1068 Lopez Fall",
      company: "Williams-Lawrence and Sons",
      companyEmail: "melvindavis@allen.info",
      contact: "(739) 745-9728",
      name: "Megan Roberts",
      country: "Mexico",
    },
    {
      id: 5027,
      address: "86691 Mackenzie Light Suite 568",
      company: "Deleon Inc LLC",
      companyEmail: "gjordan@fernandez-coleman.com",
      contact: "(682) 804-6506",
      name: "Mary Garcia",
      country: "Costa Rica",
    },
    {
      id: 5028,
      address: "86580 Sarah Bridge",
      company: "Farmer, Johnson and Anderson Group",
      companyEmail: "robertscott@garcia.com",
      contact: "(775) 366-0411",
      name: "Crystal Mays",
      country: "Cameroon",
    },
    {
      id: 5029,
      address: "49709 Edwin Ports Apt. 353",
      company: "Sherman-Johnson PLC",
      companyEmail: "desiree61@kelly.com",
      contact: "(510) 536-6029",
      name: "Nicholas Tanner",
      country: "Macedonia",
    },
    {
      id: 5030,
      address: "3856 Mathis Squares Apt. 584",
      company: "Byrd LLC PLC",
      companyEmail: "jeffrey25@martinez-hodge.com",
      contact: "(253) 230-4657",
      name: "Mr. Justin Richardson",
      country: "Congo",
    },
    {
      id: 5031,
      address: "141 Adrian Ridge Suite 550",
      company: "Stone-Zimmerman Group",
      companyEmail: "john77@anderson.net",
      contact: "(612) 546-3485",
      name: "Jennifer Summers",
      country: "Falkland Islands (Malvinas)",
    },
    {
      id: 5032,
      address: "01871 Kristy Square",
      company: "Yang, Hansen and Hart PLC",
      companyEmail: "ywagner@jones.com",
      contact: "(203) 601-8603",
      name: "Richard Payne",
      country: "Germany",
    },
    {
      id: 5033,
      address: "075 Smith Views",
      company: "Jenkins-Rosales Inc",
      companyEmail: "calvin07@joseph-edwards.org",
      contact: "(895) 401-4255",
      name: "Lori Wells",
      country: "Colombia",
    },
    {
      id: 5034,
      address: "2577 Pearson Overpass Apt. 314",
      company: "Mason-Reed PLC",
      companyEmail: "eric47@george-castillo.com",
      contact: "(602) 336-9806",
      name: "Tammy Sanchez",
      country: "Paraguay",
    },
    {
      id: 5035,
      address: "1770 Sandra Mountains Suite 636",
      company: "Foster-Pham PLC",
      companyEmail: "jamesjoel@chapman.net",
      contact: "(936) 550-1638",
      name: "Dana Carey",
      country: "Western Sahara",
    },
    {
      id: 5036,
      address: "78083 Laura Pines",
      company: "Richardson and Sons LLC",
      companyEmail: "pwillis@cross.org",
      contact: "(687) 660-2473",
      name: "Andrew Burns",
      country: "Bhutan",
    },
  ],
}


/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/company/companies').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.companies.filter(
    company =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (company.companyEmail.toLowerCase().includes(queryLowered) ||
        company.name.toLowerCase().includes(queryLowered)),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      companies: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})


// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/company/agencies').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.agencies.filter(
    agency =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (agency.companyEmail.toLowerCase().includes(queryLowered) ||
      agency.name.toLowerCase().includes(queryLowered)),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      agencies: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Company
// ------------------------------------------------
mock.onGet(/\/apps\/company\/companies\/\d+/).reply(config => {
  // Get event id from URL
  let companyId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  companyId = Number(companyId)

  const companyIndex = data.companies.findIndex(e => e.id === companyId)
  const company = data.companies[companyIndex]
  const responseData = {
    company,
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905',
    },
  }

  if (company) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/company/countries').reply(() => {
  const { countries } = data
  return [200, countries]
})
