import EventEmitter from 'events'
import router from '@/router'
import Vue from 'vue'
import { initialAbility } from '@/libs/acl/config'
import store from '@/store'
import authDefaultConfig from './authConfig'


export default class AuthService extends EventEmitter {
  // Will be used by this service for making API calls
  axiosIns = null

  authConfig = { ...authDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, OverrideConfig) {
    super()
    if (process.env.VUE_APP_AUTH == "CL") {
      this.axiosIns = axiosIns
      this.authConfig = { ...this.authConfig, ...authDefaultConfig }
      this.axiosIns.interceptors.request.use(
        config => {
          // Get authState from localStorage
          const authState = this.getAuthState()
          console.log('auth state')
          console.log(authState)
          console.log(config)
          // If authState is present add it to request's Authorization Header
          if (authState == "Authorized") {
            config.credentials = "include"
            const accessToken = this.getToken()
            const tokenType = this.getTokenType()
            // If token is present add it to request's Authorization Header
            if (accessToken) {
              // eslint-disable-next-line no-param-reassign
              config.headers.Authorization = `${tokenType} ${accessToken}`
            }
            return config
            // eslint-disable-next-line no-param-reassign
            // config.headers.Authorization = `${this.authConfig.tokenType} ${accessToken}`
          }
          return config
        },
        error => Promise.reject(error),
      )

      // Add request/response interceptor
      this.axiosIns.interceptors.response.use(
        response => response,
        error => {
          // const { config, response: { status } } = error
          const { config, response } = error
          const originalRequest = config
          console.log('originalRequest.url')
          console.log(originalRequest.url)
          console.log('response')
          console.log(response)
          // console.log(this.isAlreadyFetchingAccessToken)
          if (response && response.status === 403) {
          // if (originalRequest.url == this.authConfig.refreshEndpoint && response && response.status === 403) {
            // console.log("logout REFRESH TOKEN")
            this.setAuthState("notAuthenticated")
            // this.isAlreadyFetchingAccessToken = false
            localStorage.removeItem('userData')
            store.commit('appConfig/UPDATE_USER', null)
            this.setToken(null)
            this.setTokenType(null)
            console.log('LOGGED OUT - SESSION EXPIRED')
            Vue.prototype.$ability.update(initialAbility)
            window.location.replace('/')
          }
          if (response && response.status === 403) {
            console.log("===================QUI================")
            return window.location.replace('/oauth/login')
            // if (!this.isAlreadyFetchingAccessToken) {
            //   this.isAlreadyFetchingAccessToken = true
            //   this.refreshToken().then(r => {
            //     console.log("===================VEDIAMO================")
            //     console.log(r)
            //     console.log("===================VEDIAMO================")
            //     // Update accessToken in localStorage
            //     this.setToken(r.data.accessToken)
            //     this.setRefreshToken(r.data.refreshToken)

            //     this.onAccessTokenFetched(r.data.accessToken)
            //     this.isAlreadyFetchingAccessToken = false
            //   }).catch(err => {
            //     return window.location.replace('/oauth/login')
            //     console.log("===================VEDIAMO ERR================")
            //     console.log(err)
            //     console.log("===================VEDIAMO ERR================")
            //     this.isAlreadyFetchingAccessToken = false
            //     this.setAuthState("notAuthenticated")
            //     this.isAlreadyFetchingAccessToken = false
            //     localStorage.removeItem('userData')
            //     this.setToken(null)
            //     this.setTokenType(null)
            //     console.log('LOGGED OUT - SESSION EXPIRED')
            //     Vue.prototype.$ability.update(initialAbility)
            //     window.location.replace('/')
            //   })
            // }
            // const retryOriginalRequest = new Promise(resolve => {
            //   this.addSubscriber(accessToken => {
            //     // Make sure to assign accessToken according to your response.
            //     // Check: https://pixinvent.ticksy.com/ticket/2413870
            //     // Change Authorization header
            //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
            //     resolve(this.axiosIns(originalRequest))
            //   })
            // })
            // return retryOriginalRequest
          }
          /* if (originalRequest.url == this.authConfig.refreshEndpoint && response && response.status === 401) {
            console.log("logout REFRESH TOKET")
            localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
            localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userData')
            this.isAlreadyFetchingAccessToken = false
            // Redirect to login page
            Vue.prototype.$ability.update(initialAbility)
            console.log("$$$$$$$$$$$$$$$$$$$$$$ ROUTER GO $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
            // eslint-disable-next-line no-restricted-globals
            //           location.reload()
            window.location.replace('/')
            // router.push({ name: 'auth-login' })
            // Reset ability
            // const vm = getCurrentInstance().proxy
            // vm.$ability.update(initialAbility)
          }
          console.log(this.isAlreadyFetchingAccessToken)
          // if (status === 401) {
          if (response && response.status === 401) {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true
              this.refreshToken().then(r => {
                // Update accessToken in localStorage
                this.setToken(r.data.accessToken)
                this.setRefreshToken(r.data.refreshToken)

                this.onAccessTokenFetched(r.data.accessToken)
                this.isAlreadyFetchingAccessToken = false
              })
            }
            const retryOriginalRequest = new Promise(resolve => {
              this.addSubscriber(accessToken => {
                // Make sure to assign accessToken according to your response.
                // Check: https://pixinvent.ticksy.com/ticket/2413870
                // Change Authorization header
                originalRequest.headers.Authorization = `${this.authConfig.tokenType} ${accessToken}`
                resolve(this.axiosIns(originalRequest))
              })
            })
            return retryOriginalRequest
          }
          */
          return Promise.reject(error)
        },
      )
    }
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }


  getAuthState() {
    return localStorage.getItem(this.authConfig.AuthStateKeyName)
  }

  setAuthState(value) {
    localStorage.setItem(this.authConfig.AuthStateKeyName, value)
  }

  getToken() {
    return localStorage.getItem(this.authConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.authConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.authConfig.storageTokenKeyName, value)
  }

  setTokenType(value) {
    localStorage.setItem(this.authConfig.storageTokenTypeName, value)
  }

  getTokenType() {
    return localStorage.getItem(this.authConfig.storageTokenTypeName)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.authConfig.storageRefreshTokenKeyName, value)
  }

  refreshToken() {
    console.log("REFRESHHHHH")
    return this.axiosIns.post(this.authConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  login(...args) {
    return this.axiosIns.post(this.authConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.authConfig.registerEndpoint, ...args)
  }

  async fetchAuthUser(...args) {
    if (process.env.VUE_APP_AUTH == 'CL') {
      await this.axiosIns.get(this.authConfig.authUserEndpoint, ...args).then(res => {
        console.log("fetchAuthUser RESPONSE")
        console.log(res)
        if (res.data.authState == "notAuthenticated") {
          return window.location.replace('/oauth/login')
        }
        if (res.data.userData) {
          this.setAuthState(res.data.authState)
          localStorage.setItem('userData', JSON.stringify(res.data.userData))
          Vue.prototype.$ability.update(res.data.userData.ability)
          // Vue.prototype.$ability.update(initialAbility)
          this.setToken(res.data.access_token)
          this.setTokenType(res.data.token_type)
          console.log("==================COMMIT USERDATA=====================")
          store.commit('appConfig/UPDATE_USER', res.data.userData)
          // userData.value = res.data.userData
          // window.location.href = "/"
        }

        return "OK"
      })
        .catch(error => {
          console.log("fetchAuthUser ERROR")
          console.log(error)
          return "NO"
        })
    }
    return "OP"
  }

  logout(...args) {
    console.log('logout')
    this.setAuthState('notAuthenticated')
    localStorage.removeItem('userData')
    localStorage.removeItem(this.authConfig.storageTokenKeyName)
    localStorage.removeItem(this.authConfig.storageRefreshTokenKeyName)
    console.log('initialAbility')
    // Reset ability
    Vue.prototype.$ability.update(initialAbility)
    console.log('get logout')
    return window.location.replace(this.authConfig.logoutEndpoint)
    // return this.axiosIns.get(this.authConfig.logoutEndpoint, ...args).then(() => {

    // })
  }
}
