export default [
  // *===============================================---*
  // *--------- DASHBOARD ------------------------------------*
  // *===============================================---*
  {
    path: '/crm/#/*',
    name: 'crm',
    meta: {
      action: 'read',
      resource: 'LoggedIn',
      RedirectExternalUrl: "/crm/#/",
    },
  },
  {
    path: '/b2b/#/*',
    name: 'b2b',
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/b2c/#/*',
    name: 'b2c',
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/scheduling/#/*',
    name: 'scheduling',
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/projects/#/*',
    name: 'projects',
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/mes/#/*',
    name: 'mes',
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/edi/#/*',
    name: 'edi',
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/services/#/*',
    name: 'services',
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/sync/#/*',
    name: 'sync',
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/dashboard/home',
    name: 'dashboard-home',
    component: () => import('@/views/jviews/dashboard/home.vue'),
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  // *===============================================---*
  // *--------- APP DEALER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/dashboard/home',
    name: 'dashboard-home',
    component: () => import('@/views/jviews/dashboard/home.vue'),
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/dealer/companies',
    name: 'companylist',
    component: () => import('@/views/jviews/dealer/Company/CompanyList.vue'),
    meta: {
      action: 'read',
      resource: 'Compagnie',
    },
  },
  {
    path: '/dealer/programs',
    name: 'programlist',
    component: () => import('@/views/jviews/dealer/Programs/ProgramList.vue'),
    meta: {
      action: 'read',
      resource: 'Programmi',
    },
  }, {
    path: '/dealer/programs/edit/:id',
    name: 'edit-program',
    component: () => import('@/views/jviews/dealer/Programs/AddProgram.vue'),
    meta: {
      action: 'read',
      resource: 'Programmi',
    },
  },
  {
    path: '/dealer/programs/new',
    name: 'new-program',
    component: () => import('@/views/jviews/dealer/Programs/AddProgram.vue'),
    meta: {
      action: 'read',
      resource: 'Programmi',
    },
  },
  {
    path: '/dealer/zone/',
    name: 'zone',
    component: () => import('@/views/jviews/dealer/Zona/ZonaList.vue'),
    meta: {
      action: 'read',
      resource: 'Zone',
    },
  },
  {
    path: '/dealer/zone/new/:id',
    name: 'new-zona',
    component: () => import('@/views/jviews/dealer/Zona/AddZone.vue'),
    meta: {
      action: 'read',
      resource: 'Zone',
    },
  },
  {
    path: '/dealer/zone/edit/:id',
    name: 'edit-zona',
    component: () => import('@/views/jviews/dealer/Zona/AddZone.vue'),
    meta: {
      action: 'read',
      resource: 'Zone',
    },
  },
  {
    path: '/dealer/agencies',
    name: 'agencylist',
    component: () => import('@/views/jviews/dealer/Agency/AgencyList.vue'),
    meta: {
      action: 'write',
      resource: 'Dealer',
    },
  },
  {
    path: '/dealer/agencies/:id',
    name: 'agencyedit',
    component: () => import('@/views/jviews/dealer/Agency/AgencyEdit.vue'),
    meta: {
      action: 'read',
      resource: 'Dealer',
    },
  },
  {
    path: '/dealer/products',
    name: 'productlist',
    component: () => import('@/views/jviews/dealer/Product/ProductList.vue'),
    meta: {
      action: 'read',
      resource: 'Prodotti',
    },
  },
  {
    path: '/dealer/products/:id',
    name: 'add-edit-product',
    component: () => import('@/views/jviews/dealer/Product/AddEditProduct.vue'),
    meta: {
      action: 'read',
      resource: 'Prodotti',
    },
  },
  {
    path: '/dealer/bundles',
    name: 'bundlelist',
    component: () => import('@/views/jviews/dealer/Bundle/BundleList.vue'),
    meta: {
      action: 'read',
      resource: 'Prodotti',
    },
  },
  {
    path: '/dealer/bundles/:id',
    name: 'add-edit-bundle',
    component: () => import('@/views/jviews/dealer/Bundle/AddEditBundle.vue'),
    meta: {
      action: 'read',
      resource: 'Prodotti',
    },
  },
  {
    path: '/dealer/timesheet',
    name: 'dealer-timesheet',
    component: () => import('@/views/jviews/dealer/WorkHours.vue'),
    meta: {
      action: 'read',
      resource: 'LoggedIn',
    },
  },
  {
    path: '/dealer/estimator',
    name: 'estimator-wizard',
    component: () => import('@/views/jviews/dealer/Estimator/WizardEstimator.vue'),
    meta: {
      action: 'read',
      resource: 'Preventivatore',
    },
  },
  {
    path: '/dealer/quotes',
    name: 'quotes-list',
    component: () => import('@/views/jviews/dealer/Estimator/QuotesList.vue'),
    meta: {
      action: 'read',
      resource: 'Preventivatore',
    },
  },
  {
    path: '/dealer/quotes/:id',
    name: 'edit-quotation',
    component: () => import('@/views/jviews/dealer/Estimator/WizardEstimator.vue'),
    meta: {
      action: 'read',
      resource: 'Preventivatore',
    },
  },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/japps/users/list',
    name: 'japps-users-list',
    component: () => import('@/views/jviews/user/users-list/UsersList.vue'),
    meta: {
      action: 'read',
      resource: 'Utenti',
    },
  },
  {
    path: '/japps/users/view/:id',
    name: 'japps-users-view',
    component: () => import('@/views/jviews/user/users-view/UsersView.vue'),
    meta: {
      action: 'read',
      resource: 'Utenti',
    },
  },
  {
    path: '/japps/users/edit/:id',
    name: 'japps-users-edit',
    component: () => import('@/views/jviews/user/users-edit/UsersEdit.vue'),
    meta: {
      action: 'write',
      resource: 'Utenti',
    },
  },
  {
    path: '/japps/pages/account-setting',
    name: 'jpages-account-setting',
    component: () => import('@/views/jviews/account-setting/AccountSetting.vue'),
    meta: {
      action: 'read',
      resource: 'LoggedIn',
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- Roles & Permissions --------------------*
  // *===============================================---*
  {
    path: '/japps/roles/list',
    name: 'japps-roles-list',
    component: () => import('@/views/jviews/roles/RolesList.vue'),
    meta: {
      pageTitle: 'Roles List',
      breadcrumb: [
        {
          text: 'Roles List',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Utenti',
    },
  },
  // *===============================================---*
  // *--------- CONFIGURAZIONI -------------------------*
  // *===============================================---*
  {
    path: '/japps/pages/app-setting',
    name: 'jpages-app-setting',
    component: () => import('@/views/jviews/admin/Settings.vue'),
    meta: {
      pageTitle: 'App Settings',
      breadcrumb: [
        {
          text: 'App Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/japps/pages/portal-configs',
    name: 'portal-configs',
    component: () => import('@/views/jviews/admin/Configs.vue'),
    meta: {
      pageTitle: 'Portal Configurations',
      breadcrumb: [
        {
          text: 'Portal Configurations',
          active: true,
        },
      ],
    },
  },
]
