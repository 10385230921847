export default {
  // Endpoints
  loginEndpoint: `/oauth/login`,
  authUserEndpoint: `/oauth/user`,
  registerEndpoint: '/oauth/register',
  refreshEndpoint: '/oauth/refreshtoken',
  logoutEndpoint: '/oauth/logout',
  resetPasswordEndpoint: `/oauth/resetpassword`,
  forgotPasswordEndpoint: `/oauth/forgotpassword`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  AuthStateKeyName: 'AuthState',
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageTokenTypeName: 'tokentype',
  storageExpireInName: 0,
}

